import { SET_RESTAURANT_LANGUAGES } from "../business/constants";

const initState = [];

export default function businessLanguageReducer(state = initState, action) {
  switch (action.type) {
    case SET_RESTAURANT_LANGUAGES:

      return action.payload;

    default:
      return state;
  }
}
