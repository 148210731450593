import { all, call, put, select, takeEvery, fork } from "redux-saga/effects";

import {
  INIT,
  LOAD_RESTAURANT_INFO,
  SET_REGIMES,
  SET_ORDER_STATUS,
  SET_PAYMENTS,
  SET_SOCIALS,
  SET_SERVICES,
  SET_SETTING_PRODUCT,
  SET_ACTIVITIES,
  SET_SPECIALITY_FOODS,
  SET_CASH_REGISTER,
  SET_PRICE_VALUES,
  SET_DELIVERY_PARTNER,
  UPLOAD_IMG_LAYOUT,
  UPLOAD_IMG_CATALOG,
  SET_RESTAURANT_FILTER_PRODUCT_ATTRIBUTES,
  SET_RESTAURANT_PRODUCTS,
  SET_RESTAURANT_SHOWTIMES,
  SET_STATIONS,
  SET_ZONES,
  SET_RESTAURANT_INFO,
  SET_RESTAURANT_MENUS,
  SET_RESTAURANT_OPTIONS,
  SET_RESTAURANT_PRICINGS,
  SET_RESTAURANT_PROMOCODES,
  SET_RESTAURANT_ATTRIBUTES,
  SET_RESTAURANT_LANGUAGES,
  SET_RESTAURANT_REGIMES,
  SET_RESTAURANT_PAYMENTS,
  SET_INGREDIENT_CATEGORIES,
  SET_COUNTRY,
  SET_TAX,
  SET_USER_LANGUAGE,
} from "./constants";
import { push } from "react-router-redux";
import request from "helpers/request";
import getCurrentBusinessUuid from "helpers/getCurrentBusinessUuid";
import queryString from "query-string";

const getLanguageSwitcher = (state) => state.LanguageSwitcher;
const getRouter = (state) => state.router;

window.fooundinit = false;

export function* init() {
  yield takeEvery(INIT, function* () {
    console.log("INIT");
    console.log(window.fooundinit);

    const parsed = queryString.parse(document.location.search);

    console.log(parsed);
    const router = yield select(getRouter);
    //console.log(8888888812);
    //console.log(router.location.pathname);
    //console.log(document.location.pathname);

    if (
      window.fooundinit === false &&
      document.location.pathname !== "/fromApp"
    ) {
      yield put({ type: LOAD_RESTAURANT_INFO });

      if (getCurrentBusinessUuid()) {
      } else {
        // document.location.href = "/dashboard/setting";

        if (
          router.location.pathname === "/reset_password" ||
          router.location.pathname === "forgotpassword"
        ) {
        } else {
          yield put(push("/dashboard/setting"));
        }
      }

      yield put({ type: "APP_READY" });
      window.fooundinit = true;
    }
  });
}

export function* loadBusinessData() {
  yield takeEvery(LOAD_RESTAURANT_INFO, function* () {
    console.log("LOAD_RESTAURANT_INFO");

    const languageSwitcher = yield select(getLanguageSwitcher);

    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", languageSwitcher.language.locale);
      //localStorage.setItem("lang", df.locale);
    }

    const lang = localStorage.getItem("lang");
    document.documentElement.setAttribute("lang", lang);

    //alert(lang);

    if (getCurrentBusinessUuid()) {
      // BUSINESS
      const url1 = `/client/${getCurrentBusinessUuid()}/client1`;
      const client1 = yield call(request.getPublic, url1);

      if (client1 && client1.data) {
        yield put({
          type: SET_RESTAURANT_INFO,
          payload: client1.data.businessInfo,
        });
      }

      // OTHER
      const url = `/client/${getCurrentBusinessUuid()}/client2`;
      const client = yield call(request.getPublic, url);

      console.log("CLIENT API");

      if (client && client.data) {
        console.log(url);

        if (client && client.data && typeof client.data.country === "object") {
          yield put({ type: SET_COUNTRY, payload: client.data.country });
        }

        if (client && client.data && typeof client.data.tax === "object") {
          yield put({ type: SET_TAX, payload: client.data.tax });
        }

        if (
          client &&
          client.data &&
          typeof client.data.orderStatus === "object"
        ) {
          yield put({
            type: SET_ORDER_STATUS,
            payload: client.data.orderStatus,
          });
        }

        if (client && client.data && typeof client.data.regimes === "object") {
          yield put({ type: SET_REGIMES, payload: client.data.regimes });
        }

        if (client && client.data && typeof client.data.payments === "object") {
          yield put({ type: SET_PAYMENTS, payload: client.data.payments });
        }

        if (client && client.data && typeof client.data.socials === "object") {
          yield put({ type: SET_SOCIALS, payload: client.data.socials });
        }

        if (client && client.data && typeof client.data.services === "object") {
          yield put({ type: SET_SERVICES, payload: client.data.services });
        }

        if (
          client &&
          client.data &&
          typeof client.data.settingProduct === "object"
        ) {
          yield put({
            type: SET_SETTING_PRODUCT,
            payload: client.data.settingProduct,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.activities === "object"
        ) {
          yield put({ type: SET_ACTIVITIES, payload: client.data.activities });
        }

        if (
          client &&
          client.data &&
          typeof client.data.specialityFoods === "object"
        ) {
          yield put({
            type: SET_SPECIALITY_FOODS,
            payload: client.data.specialityFoods,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.cashRegister === "object"
        ) {
          yield put({
            type: SET_CASH_REGISTER,
            payload: client.data.cashRegister,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.deliveryPartner === "object"
        ) {
          yield put({
            type: SET_DELIVERY_PARTNER,
            payload: client.data.deliveryPartner,
          });
        }

        if (client && client.data && typeof client.data.stations === "object") {
          yield put({ type: SET_STATIONS, payload: client.data.stations });
        }

        if (client && client.data && typeof client.data.zones === "object") {
          yield put({ type: SET_ZONES, payload: client.data.zones });
        }

        if (
          client &&
          client.data &&
          typeof client.data.priceValues === "object"
        ) {
          yield put({
            type: SET_PRICE_VALUES,
            payload: client.data.priceValues,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.ingredientCategories === "object"
        ) {
          yield put({
            type: SET_INGREDIENT_CATEGORIES,
            payload: client.data.ingredientCategories,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.businessFilteredProductAttribute === "object"
        ) {
          yield put({
            type: SET_RESTAURANT_FILTER_PRODUCT_ATTRIBUTES,
            payload: client.data.businessFilteredProductAttribute,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.businessMenu === "object"
        ) {
          yield put({
            type: SET_RESTAURANT_MENUS,
            payload: client.data.businessMenu,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.businesshowtime === "object"
        ) {
          yield put({
            type: SET_RESTAURANT_SHOWTIMES,
            payload: client.data.businesshowtime,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.businessPromocode === "object"
        ) {
          yield put({
            type: SET_RESTAURANT_PROMOCODES,
            payload: client.data.businessPromocode,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.businessPricing === "object"
        ) {
          yield put({
            type: SET_RESTAURANT_PRICINGS,
            payload: client.data.businessPricing,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.businessAttribute === "object"
        ) {
          yield put({
            type: SET_RESTAURANT_ATTRIBUTES,
            payload: client.data.businessAttribute,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.businessLanguage === "object"
        ) {
          yield put({
            type: SET_RESTAURANT_LANGUAGES,
            payload: client.data.businessLanguage,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.businessRegime === "object"
        ) {
          yield put({
            type: SET_RESTAURANT_REGIMES,
            payload: client.data.businessRegime,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.businessPayment === "object"
        ) {
          yield put({
            type: SET_RESTAURANT_PAYMENTS,
            payload: client.data.businessPayment,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.businessOption === "object"
        ) {
          yield put({
            type: SET_RESTAURANT_OPTIONS,
            payload: client.data.businessOption,
          });
        }

        if (
          client &&
          client.data &&
          typeof client.data.userLanguagesV2 === "object"
        ) {
          yield put({
            type: SET_USER_LANGUAGE,
            payload: client.data.userLanguagesV2,
          });
        }
      }

      // PRODUCTS
      const url3 = `/client/${getCurrentBusinessUuid()}/client3`;
      const client3 = yield call(request.getPublic, url3);

      if (client3 && client3.data) {
        yield put({
          type: SET_RESTAURANT_PRODUCTS,
          payload: client3.data.businessProduct,
        });
      }
    }
  });
}

export function* changeLang() {
  yield takeEvery("CHANGE_LANGUAGE", function* (payload) {
    yield localStorage.setItem("lang", payload.language.locale);

    //yield put(actionsLang.changeLanguageSuccess());
  });
}

export function* uploadImgLayout() {
  yield takeEvery(UPLOAD_IMG_LAYOUT, function* (payload) {
    const result = yield call(
      request.upload,
      `/business/${getCurrentBusinessUuid()}/img_layout`,
      payload.payload
    );

    const businessInfo = yield call(
      request.get,
      `/business/${getCurrentBusinessUuid()}`
    );
    yield put({ type: SET_RESTAURANT_INFO, payload: businessInfo.data });
  });
}

export function* uploadImgCatalog() {
  yield takeEvery(UPLOAD_IMG_CATALOG, function* (payload) {
    const result = yield call(
      request.upload,
      `/business/${getCurrentBusinessUuid()}/img_catalog`,
      payload.payload
    );

    const businessInfo = yield call(
      request.get,
      `/business/${getCurrentBusinessUuid()}`
    );
    yield put({ type: SET_RESTAURANT_INFO, payload: businessInfo.data });
  });
}

export default function* rootSaga() {
  yield all([
    fork(init),
    fork(loadBusinessData),
    fork(changeLang),
    //fork(changeLangSuccess),
    fork(uploadImgLayout),
    fork(uploadImgCatalog),
  ]);
}
