import { SET_CASH_REGISTER } from "../business/constants";

const initState = [];

export default function cashregisterReducer(state = initState, action) {
  switch (action.type) {
    case SET_CASH_REGISTER:
      return action.payload;

    default:
      return state;
  }
}
