import { client } from "settings";


export default function getWebsiteDomain(business) {


  if(business.whitelabel && typeof business.whitelabel.domain === 'string') {
    return business.whitelabel.domain;
  }


  return 'menuchic.com'
}




