import {
  all,
  call,
  delay,
  takeEvery,
  put,
  select,
  fork,
} from "redux-saga/effects";
import { push } from "react-router-redux";
import { getToken, clearToken, clearBusinessId } from "helpers/utility";
import actions from "./actions";
import actionsUser from "../user/actions";
import request from "helpers/request";
import getCurrentBusinessUuid from "helpers/getCurrentBusinessUuid";
import * as Sentry from "@sentry/react";
import queryString from "query-string";

const fakeApiCall = true; // auth0 or express JWT

const getState = (state) => state;
const getRouter = (state) => state.router;

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {
    console.log("LOGIN_REQUEST");

    const result = yield call(request.login, payload);

    //console.log(1234);
    //console.log(result);
    //console.log(payload);
    //return false;

    /*
    document.location.href = "/code_verification";
    //yield put(push('/code_verification'));
    return false;
    */

    if (result && result.action && result.action === "send code") {
      try {
        sessionStorage.setItem("cvp", payload.password);
      } catch (e) {}

      document.location.href = "/code_verification?email=" + payload.username;
      //yield put(push('/code_verification?email='+ payload.username));
    } else {
      if (result) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: result.access_token,
          profile: "Profile",
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    console.log("loginSuccess");
    console.log(payload);
    const state = yield select(getState);
    console.log(state);

    try {
      yield localStorage.setItem("id_token", payload.token);
      yield delay(1000);

      const result = yield call(request.getUser, payload.token);

      console.log("RESULT");
      console.log(result);
      console.log(state.router.location.pathname);

      if (result && result.data) {
        yield put({ type: actionsUser.SET_USER, payload: result.data });
      }

      yield put({ type: "INIT", payload: {} });

      Sentry.setContext("character", {
        lastname: result?.data?.lastname,
        firstname: result?.data?.firstname,
        email: result?.data?.email,
      });

      if (getCurrentBusinessUuid()) {
        console.log(222222);

        if (state.router.location.pathname === "/reset_password") {
          return;
        } else if (state.router.location.pathname === "/forgotpassword") {
          return;
        } else if (state.router.location.pathname === "/fromApp") {
          const parsed = queryString.parse(document.location.search);
          console.log(88888);
          console.log(parsed);

          if (parsed.redirect) {
            document.location.href = parsed.redirect;
          } else if (parsed.product_uuid) {
            if (parsed.type === "ingredientAdded") {
              document.location.href = `/dashboard/ingredientAdded/edit/${parsed.product_uuid}`;
            } else if (parsed.type === "ingredientIncluded") {
              document.location.href = `/dashboard/ingredientIncluded/edit/${parsed.product_uuid}`;
            } else {
              document.location.href = `/dashboard/product/edit/${parsed.product_uuid}`;
              //yield put(push(`/dashboard/product/edit/${parsed.product_uuid}`));
            }
          } else {
            if (parsed.type === "addProduct") {
              document.location.href = "/dashboard/product/create";
            } else if (parsed.type === "addIngredient") {
              document.location.href = "/dashboard/ingredientAdded/create";
            } else {
              document.location.href = "/dashboard/product";
            }
          }
        } else if (
          state.router.location.pathname === "/" ||
          state.router.location.pathname === "/signin" ||
          state.router.location.pathname === "/code_verification"
        ) {
          document.location.href = "/dashboard";
          //yield put(push("/dashboard"));
        } else {
          yield put(push(state.router.location.pathname));
        }
      } else {
        if (state.router.location.pathname === "/dashboard/setting") {
          return;
        }

        console.log(333333);
        document.location.href = "/dashboard/setting";
      }
    } catch (e) {
      console.log("AAAAAAAA");
      console.log(e);
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken();
    clearBusinessId();
    yield put(push("/"));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get("idToken");
    console.log(12345678);
    console.log(token);
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: "Profile",
      });
    }
  });
}

export function* forgetRequest() {
  yield takeEvery("FORGET_REQUEST", function* ({ payload }) {
    /*
        const result = yield call(request.login, payload);

        if(result) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                token: result,
                profile: 'Profile',
            });
        } else {
            yield put({ type: actions.LOGIN_ERROR });
        }
        */
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(forgetRequest),
  ]);
}
