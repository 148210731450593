import { SET_RESTAURANT_PRICINGS } from "../business/constants";

const initState = [];

export default function businessPricingReducer(state = initState, action) {
  switch (action.type) {
    case SET_RESTAURANT_PRICINGS:

      return action.payload;

    default:
      return state;
  }
}
