import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius } from 'settings/style-util';
import WithDirection from 'settings/withDirection';
import isMobile from 'helpers/isMobile';


const StyleBar = styled.div`
        .isomorphicSidebar {
          z-index: 1000;
          background: ${palette('grayscale', 5)};
          width: 280px;
          flex: 0 0 280px;

          .scrollarea {
            height: calc(100vh - 70px);
          }
          .businessName {
              display: flex;
              flexDirection: row;
              color: ${palette('secondary', 10)};
              align-items: center;
              justify-content: center;
              margin-bottom: 15px;
              ${
                isMobile() ?
                  "font-size: 30px;"
                :
                  "font-size: 21px;"
              }
              font-weight: 300;
              line-height: 30px;
              border-bottom: 1px thin #f1f3f6;
          }
          @media only screen and (max-width: 767px) {
            width: 240px !important;
            flex: 0 0 240px !important;
          }

          &.ant-layout-sider-collapsed {
            @media only screen and (max-width: 767px) {
              width: 0;
              min-width: 0 !important;
              max-width: 0 !important;
              flex: 0 0 0 !important;
            }
          }

          .isoLogoWrapper {
            height: 70px;
            background: #efefef;
            margin: 0;
            padding: 10px;
            text-align: center;
            overflow: hidden;
            ${borderRadius()};

            h3 {
              a {
                font-size: 21px;
                font-weight: 300;
                line-height: 70px;
                letter-spacing: 3px;
                text-transform: uppercase;
                color: ${palette('grayscale', 6)};
                display: block;
                text-decoration: none;
              }
            }
          }

          &.ant-layout-sider-collapsed {
            .isoLogoWrapper {
              padding: 0;

              h3 {
                a {
                  font-size: 27px;
                  font-weight: 500;
                  letter-spacing: 0;
                }
              }
            }
          }

          .isoDashboardMenu {
            padding-top: 35px;
            padding-bottom: 35px;
            background: transparent;

            a {
              text-decoration: none;
              font-weight: 400;
            }

            .ant-menu-item {
              width: 100%;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-align: center;
              align-items: center;
              padding: 0 24px;
              margin: 0;
            }

            .isoMenuHolder {
              display: flex;
              align-items: center;
              color: ${palette('secondary', 10)};

              i {
                font-size: 19px;
                color: inherit;
                margin: ${props =>props['data-rtl'] === 'rtl' ? '0 0 0 30px' : '0 30px 0 0'};
                width: 18px;
                ${transition()};
              }
            }

            .anticon {
              font-size: 18px;
              margin-right: 30px;
              ${transition()};
              color: ${palette('secondary', 10)};
              &:hover {
                background-color: ${palette('grayscale', 2)};
              }
            }

            .nav-text {
              ${
                isMobile() ?
                  "font-size: 20px;"
                :
                  "font-size: 18px;"
              }
              color: inherit;
              font-weight: 400;
              ${transition()};
            }

            .ant-menu-item:not(.ant-menu-item-selected){
              a {
                color: ${palette('secondary', 10)} !important;
              }
            }
            .ant-menu-item-selected {
              background-color: ${palette('grayscale', 2)} !important;
              a {
                color: ${palette('color', 7)}
              }
              .anticon {
                color: #fff;
              }

              i {
                color: #fff;
              }
              .nav-text {
                color: #fff;
              }
            }

            > li {
              &:hover {
                background-color: ${palette('grayscale', 2)};
                i,.nav-text {
                  color: ${palette('secondary', 10)};
                }
              }
            }
          }

          .ant-menu-dark .ant-menu-inline.ant-menu-sub {
            background: ${palette('secondary', 5)};
          }

          .ant-menu-submenu-inline,
          .ant-menu-submenu-vertical {
            > .ant-menu-submenu-title {
              width: 100%;
              display: flex;
              align-items: center;
              padding: 0 24px;

              > span {
                display: flex;
                align-items: center;
              }

              .ant-menu-submenu-arrow {
                left: ${props => (props['data-rtl'] === 'rtl' ? '25px' : 'auto')};
                right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '25px')};

                background: ${palette('secondary', 10)} !important;
                opacity: 1 !important;

                &:before,
                &:after {
                  background: ${palette('secondary', 10)} !important;
                  opacity: 1 !important;
                  width: 8px;
                  ${transition()};
                }

                &:before {
                  transform: rotate(-45deg) translateX(3px);
                }

                &:after {
                  transform: rotate(45deg) translateX(-3px);
                }

                ${'' /* &:after {
                  content: '\f123';
                  font-family: 'Ionicons' !important;
                  font-size: 16px;
                  color: inherit;
                  left: ${props => (props['data-rtl'] === 'rtl' ? '16px' : 'auto')};
                  right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '16px')};
                  ${transition()};
                } */};
              }

              &:hover {
                .ant-menu-submenu-arrow {
                  &:before,
                  &:after {
                    background: ${palette('secondary', 10)} !important
                    opacity: 1 !important
                  }
                }
              }
            }

            .ant-menu-inline,
            .ant-menu-submenu-vertical {
              > li:not(.ant-menu-item-group) {
                padding-left: ${props =>props['data-rtl'] === 'rtl' ? '0px !important' : '48px !important'};
                padding-right: ${props =>props['data-rtl'] === 'rtl' ? '48px !important' : '0px !important'};
                font-size: 16px;
                font-weight: 400;
                margin: 0;
                color: inherit;
                ${transition()};

                &:hover {
                  background-color: ${palette('grayscale', 2)};
                  a {
                    color: ${palette('secondary', 10)} !important;
                  }
                }
              }

              .ant-menu-item-group {
                padding-left: 0;

                .ant-menu-item-group-title {
                  padding-left: 100px !important;
                }
                .ant-menu-item-group-list {
                  .ant-menu-item {
                    padding-left: 125px !important;
                  }
                }
              }
            }

            .ant-menu-sub {
              box-shadow: none;
              background-color: ${palette('grayscale', 3)} !important;
            }
          }

          &.ant-layout-sider-collapsed {
            .nav-text {
              display: none;
            }

            .ant-menu-submenu-inline >  {
              .ant-menu-submenu-title:after {
                display: none;
              }
            }

            .ant-menu-submenu-vertical {
              > .ant-menu-submenu-title:after {
                display: none;
              }

              .ant-menu-sub {
                background-color: transparent !important;

                .ant-menu-item {
                  height: 35px;
                }
              }
            }
          }
        }
      `;

export default WithDirection(StyleBar);