import update from "immutability-helper";
import { SET_RESTAURANT_MENUS, UPDATE_RESTAURANT_MENU } from "../business/constants";

const initState = [];

export default function businessMenuReducer(state = initState, action) {
  switch (action.type) {
    case SET_RESTAURANT_MENUS:
      return action.payload;

    case UPDATE_RESTAURANT_MENU: {

      const index = state.findIndex(
        item => item.uuid === action.payload.uuid
      );


      if (index !== -1) {

        return update(state, {
          [index]: { $set: action.payload }
        });
      }

      return state;
    }


    default:
      return state;
  }
}
