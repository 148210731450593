import translateMsg from "./translateMsg";

export const getMenuItemName = (businessInfo, t) => {
  if (businessInfo.label_website_tab === "CATALOGUE") {
    return translateMsg(t, "webapp", "pwa_ui_catalog");
  }
  if (businessInfo.label_website_tab === "MENU") {
    return translateMsg(t, "webapp", "pwa_ui__MENU");
  }

  if (businessInfo.label_website_tab === "CARTE") {
    return translateMsg(t, "webapp", "pwa_ui__CARTE");
  }

  if (businessInfo.label_website_tab === "PRODUITS") {
    return translateMsg(t, "webapp", "pwa_ui__PRODUITS");
  }

  if (businessInfo.label_website_tab === "SERVICES") {
    return translateMsg(t, "webapp", "pwa_ui__SERVICES");
  }

  return translateMsg(t, "webapp", "pwa_ui__MENU");
};

export default getMenuItemName;
