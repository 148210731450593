import { SET_ZONES } from "../business/constants";

const initState = [];

export default function zoneReducer(state = initState, action) {
  switch (action.type) {
    case SET_ZONES:
      return action.payload;

    default:
      return state;
  }
}
