import { SET_ORDER_STATUS } from "../business/constants";

const initState = [];

export default function orderSTatusReducer(state = initState, action) {
  switch (action.type) {
    case SET_ORDER_STATUS:

      return action.payload;

    default:
      return state;
  }
}
