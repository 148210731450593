import {
  INIT,
  LOAD_RESTAURANT_INFO,
  UPDATE_RESTAURANT_INFO,
  UPDATE_RESTAURANT_ADDRESS,
  ADD_RESTAURANT_PRODUCT,
  DELETE_RESTAURANT_PRODUCT,
  UPDATE_RESTAURANT_PRODUCT,
  ADD_RESTAURANT_SHOWTIME,
  DELETE_RESTAURANT_SHOWTIME,
  UPDATE_RESTAURANT_SHOWTIME,
  DELETE_RESTAURANT_OPTION,
  ADD_RESTAURANT_OPTION,
  UPDATE_RESTAURANT_OPTION,
  DELETE_RESTAURANT_OPTION_VALUE,
  ADD_RESTAURANT_OPTION_VALUE,
  UPDATE_RESTAURANT_OPTION_VALUE,
  SET_RESTAURANT_PRICINGS,
  SET_RESTAURANT_REGIMES,
  SET_RESTAURANT_PAYMENTS,
  SET_RESTAURANT_LANGUAGES,
  SET_RESTAURANT_MENUS,
  UPLOAD_IMG_LAYOUT,
  UPLOAD_IMG_CATALOG,
  UPDATE_RESTAURANT_MENU,
  SET_RESTAURANT_PRODUCTS,
  SET_RESTAURANT_OPTIONS,
} from "./constants";

const actions = {
  uploadImgLayout: (payload) => ({
    type: UPLOAD_IMG_LAYOUT,
    payload,
  }),

  uploadImgCatalog: (payload) => ({
    type: UPLOAD_IMG_CATALOG,
    payload,
  }),

  init: () => ({
    type: INIT,
  }),
  loadBusinessInfo: () => ({
    type: LOAD_RESTAURANT_INFO,
  }),
  updateBusinessInfo: (payload) => ({
    type: UPDATE_RESTAURANT_INFO,
    payload,
  }),
  updateBusinessAddress: (payload) => ({
    type: UPDATE_RESTAURANT_ADDRESS,
    payload,
  }),
  addBusinessProduct: (payload) => ({
    type: ADD_RESTAURANT_PRODUCT,
    payload,
  }),
  deleteBusinessProduct: (payload) => ({
    type: DELETE_RESTAURANT_PRODUCT,
    payload,
  }),
  updateBusinessProduct: (payload) => ({
    type: UPDATE_RESTAURANT_PRODUCT,
    payload,
  }),
  addBusinessShowtime: (payload) => ({
    type: ADD_RESTAURANT_SHOWTIME,
    payload,
  }),
  deleteBusinessShowtime: (payload) => ({
    type: DELETE_RESTAURANT_SHOWTIME,
    payload,
  }),
  updateShowtime: (payload) => ({
    type: UPDATE_RESTAURANT_SHOWTIME,
    payload,
  }),

  deleteBusinessOption: (payload) => ({
    type: DELETE_RESTAURANT_OPTION,
    payload,
  }),
  addBusinessOption: (payload) => ({
    type: ADD_RESTAURANT_OPTION,
    payload,
  }),
  updateBusinessOption: (payload) => ({
    type: UPDATE_RESTAURANT_OPTION,
    payload,
  }),
  deleteBusinessOptionValue: (payload) => ({
    type: DELETE_RESTAURANT_OPTION_VALUE,
    payload,
  }),
  addBusinessOptionValue: (payload) => ({
    type: ADD_RESTAURANT_OPTION_VALUE,
    payload,
  }),
  updateBusinessOptionValue: (payload) => ({
    type: UPDATE_RESTAURANT_OPTION_VALUE,
    payload,
  }),
  setBusinessPricing: (payload) => ({
    type: SET_RESTAURANT_PRICINGS,
    payload,
  }),

  setBusinessRegime: (payload) => ({
    type: SET_RESTAURANT_REGIMES,
    payload,
  }),

  setBusinessLanguages: (payload) => ({
    type: SET_RESTAURANT_LANGUAGES,
    payload,
  }),

  setBusinessPayments: (payload) => ({
    type: SET_RESTAURANT_PAYMENTS,
    payload,
  }),

  setBusinessMenus: (payload) => ({
    type: SET_RESTAURANT_MENUS,
    payload,
  }),
  updateBusinessMenu: (payload) => ({
    type: UPDATE_RESTAURANT_MENU,
    payload,
  }),

  setBusinessOption: (payload) => ({
    type: SET_RESTAURANT_OPTIONS,
    payload,
  }),

  setBusinessProducts: (payload) => ({
    type: SET_RESTAURANT_PRODUCTS,
    payload,
  }),
};

export default actions;
