export default function hasPermission(permissions, role) {
 

    if(typeof permissions === 'object') {
        const match = permissions.find((o) => o.name === role);

        if(match) {
            return true;
        }
    }

  
  
    return false;
  }
  