import { SET_USER_LANGUAGE } from "../business/constants";

const initState = [];

export default function userLanguageReducer(state = initState, action) {
    switch (action.type) {
        case SET_USER_LANGUAGE: {
            return action.payload;
        }
        default:
            return state;
    }
}
