import React from 'react';
import { Link } from 'react-router-dom';
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import { siteConfig } from 'settings';
import { clientLogoUrl } from "settings";

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper" style={{display:'flex',alignContent:'center', alignItems:'center'}}>
      {collapsed ? (
        <div>
          <h3>




            <Link to="/dashboard">
              {clientLogoUrl ? (
                <img src={clientLogoUrl} style={{maxWidth:'100%', padding:2, margin:'auto' }} />
              ) : (
                <i className={siteConfig.siteIcon} />
              )}
            </Link>
          </h3>
        </div>
      ) : (




        clientLogoUrl ? (
          <img src={clientLogoUrl} style={{ maxWidth:'90%',maxHeight:55, margin:'auto'}} />
        ) : (
          <h3>
            <Link to="/dashboard">{siteConfig.siteName}</Link>
          </h3>
        )




      )}
    </div>
  );
};
