import { SET_SETTING_PRODUCT } from "../business/constants";

const initState = [];

export default function settingProductReducer(state = initState, action) {
  switch (action.type) {
    case SET_SETTING_PRODUCT:
      return action.payload;

    default:
      return state;
  }
}
