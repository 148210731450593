import React from "react";
import { useSelector } from "react-redux";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import actions from "../../redux/business/actions";
import { useDispatch } from "react-redux";
import request from "helpers/request";
import handleAwait from "helpers/handleAwait";

function App() {
  const lang = useSelector((state) => state.lang);
  const business = useSelector((state) => state.BusinessInfo);

  const dispatch = useDispatch();

  const channel = useChannel(business?.uuid);

  useEvent(channel, "reload-product", async (o) => {
    console.log("RECEIVER PUSHER MSG: reload-product");
    console.log(o);

    if (o && o.business_uuid) {
      const [result, resultErr] = await handleAwait(
        request.get(`/client/${o.business_uuid}/client3`)
      );

      if (result && result.data && result.data.businessProduct) {
        dispatch(actions.setBusinessProducts(result.data.businessProduct));
      }
    }
  });

  useEvent(channel, "reload-specific-product", async (o) => {
    console.log("RECEIVER PUSHER MSG: reload-specific-product");
    console.log(o);

    if (o && o.business_uuid && o.product_uuid) {
      const [result, resultErr] = await handleAwait(
        request.get(`/business/${o.business_uuid}/product/${o.product_uuid}`)
      );

      if (result && result.data && result.data) {
        dispatch(actions.updateBusinessProduct(result.data));
      }
    }
  });

  useEvent(channel, "reload-ingredient-added", async (o) => {
    console.log("RECEIVER PUSHER MSG: reload-ingredient");
    console.log(o);

    /*
        dispatch(
            businessActions.fetchProducts({ uuid: business.uuid, lang: lang }),
        );
        dispatch(
            businessActions.fetchIngredientsAdded({
                uuid: business.uuid,
                lang: lang,
            }),
        );
        dispatch(
            businessActions.fetchOptions({ uuid: business.uuid, lang: lang }),
        );
        */
  });

  useEvent(channel, "reload-option", async (o) => {
    console.log("RECEIVER PUSHER MSG: reload-option");
    console.log(o);

    /*
        dispatch(
            businessActions.fetchProducts({ uuid: business.uuid, lang: lang }),
        );
        dispatch(
            businessActions.fetchIngredientsAdded({
                uuid: business.uuid,
                lang: lang,
            }),
        );
        dispatch(
            businessActions.fetchOptions({ uuid: business.uuid, lang: lang }),
        );
        */
  });

  useEvent(channel, "reload-menu", async (o) => {
    console.log("RECEIVER PUSHER MSG: reload-menu");
    console.log(o);

    if (o && o.business_uuid) {
      const [result, resultErr] = await handleAwait(
        request.get(`/business/${o.business_uuid}/menu`)
      );

      if (result && result.data) {
        dispatch(actions.setBusinessMenus(result.data));
      }
    }

    /*
        dispatch(
            businessActions.fetchMenus({ uuid: business.uuid, lang: lang }),
        );
        */
  });

  return false;
}

export default App;
