import { SET_ACTIVITIES } from "../business/constants";

const initState = [];

export default function activityReducer(state = initState, action) {
  switch (action.type) {
    case SET_ACTIVITIES:
      return action.payload;

    default:
      return state;
  }
}
