import update from "immutability-helper";
import {
  SET_RESTAURANT_OPTIONS,
  ADD_RESTAURANT_OPTION,
  DELETE_RESTAURANT_OPTION,
  UPDATE_RESTAURANT_OPTION,
  DELETE_RESTAURANT_OPTION_VALUE,
  ADD_RESTAURANT_OPTION_VALUE,
  UPDATE_RESTAURANT_OPTION_VALUE,
} from "../business/constants";

const initState = [];

export default function businessOptionReducer(state = initState, action) {
  switch (action.type) {
    case SET_RESTAURANT_OPTIONS:
      return action.payload;


    case ADD_RESTAURANT_OPTION: {

      return update(state, {
        $push: [action.payload]
      });
    }

    case DELETE_RESTAURANT_OPTION: {

      const index = state.findIndex(
        item => item.uuid === action.payload
      );
      if (index !== -1) {
        return update(state, {
          $splice: [[index, 1]]
        });
      }

      return state;
    }

    case UPDATE_RESTAURANT_OPTION: {

      const index = state.findIndex(
        item => item.uuid === action.payload.uuid
      );
      if (index !== -1) {
        return update(state, {
          [index]: { $set: action.payload }
        });
      }

      return state;
    }

    case DELETE_RESTAURANT_OPTION_VALUE: {

      const index = state.findIndex(
        item => item.uuid === action.payload.optionUuid
      );

      if (index !== -1) {
        const index1 = state[index].values.findIndex(
          item => item.id === parseInt(action.payload.optionValueId)
        );
        if (index1 !== -1) {
          return update(state, {
            [index]: { values: { $splice: [[index1, 1]] } }
          });
        }
      }

      return state;
    }

    case ADD_RESTAURANT_OPTION_VALUE: {

      const index = state.findIndex(
        item => item.uuid === action.payload.optionUuid
      );

      if (index !== -1) {
        return update(state, {
          [index]: { values: { $push: [action.payload.data] } }
        });
      }
      return state;
    }

    case UPDATE_RESTAURANT_OPTION_VALUE: {

      const index = state.findIndex(
        item => item.uuid === action.payload.optionUuid
      );


      if (index !== -1) {
        const index1 = state[index].values.findIndex(
          item => item.id === parseInt(action.payload.optionValueId)
        );

        if (index1 !== -1) {
          return update(state, {

            [index]: { values: { [index1]: { $set: action.payload.data } } }

          });
        }
      }
      return state;
    }

    default:
      return state;
  }
}
