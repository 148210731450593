import { language } from "settings";

/*
import englishLang from 'image/flag/uk.svg';
import frenchLang from 'image/flag/france.svg';
import italianLang from 'image/flag/italy.svg';
//import chineseLang from 'image/flag/china.svg';
import spanishLang from 'image/flag/spain.svg';
import dutchLang from 'image/flag/nl.svg';
import russianLang from 'image/flag/ru.svg';
import germanLang from 'image/flag/nl.svg';
import hungarianLang from 'image/flag/hu.svg';
import japaneseLang from 'image/flag/ja.svg';
import malaysianLang from 'image/flag/my.svg';
*/
const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "german",
      locale: "de",
      text: "German",
      //icon: germanLang,
    },
    {
      languageId: "english",
      locale: "en",
      text: "English",
      //icon: englishLang,
    },
    {
      languageId: "french",
      locale: "fr",
      text: "French",
      //icon: frenchLang,
    },
    {
      languageId: "spanish",
      locale: "es",
      text: "Spanish",
      //icon: spanishLang,
    },

    {
      languageId: "italian",
      locale: "it",
      text: "Italian",
      //icon: italianLang,
    },

    {
      languageId: "dutch",
      locale: "nl",
      text: "Dutch",
      //icon: dutchLang,
    },

    {
      languageId: "russian",
      locale: "ru",
      text: "Russian",
      //icon: russianLang,
    },

    {
      languageId: "hungarian",
      locale: "hu",
      text: "Hungarian",
      //icon: hungarianLang,
    },

    {
      languageId: "japanese",
      locale: "ja",
      text: "Japanese",
      //icon: japaneseLang,
    },

    {
      languageId: "malaysian",
      locale: "my",
      text: "Malaysian",
      //icon: malaysianLang,
    },

    /*
    {
      languageId: 'chinese',
      locale: 'zh',
      text: 'Chinese',
      icon: chineseLang,
    },



    */
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach((language) => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}

export function getDefaultLanguage() {
  const l = navigator.language.substring(0, 2);

  /*
  if (l === 'fr') {
    return config.options.find((o) => o.locale === 'it');
  }
  */

  const match = config.options.find((o) => o.locale === l);
  if (match) {
    return match;
  }

  return config.options.find((o) => o.locale === "fr");
}

export default config;
