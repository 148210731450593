import {logException} from 'helpers/sentry';

const handleAwait = (promise) => {
    return promise
        .then(data => ([data, undefined]))
        .catch(error => {
            logException(JSON.stringify(error));
            return Promise.resolve([undefined, error])
        });
}

export default handleAwait

//https://dev.to/sobiodarlington/better-error-handling-with-async-await-2e5m