import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import Popover from 'components/uielements/popover';
import TopbarDropdownWrapper from './topbarDropdown.style';
import ReactCountryFlag from "react-country-flag";
import actions from 'redux/languageSwitcher/actions';
import { useTranslation } from 'react-i18next';
import hasRole from "../../helpers/hasRole";
import { noConflict } from 'underscore';
import { Switch } from "antd";

function App(props) {


  const user = useSelector((state) => state.User);
  const userLanguage = useSelector((state) => state.userLanguage);
  const language = useSelector((state) => state.LanguageSwitcher.language);

  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const handleVisibleChange = () => {
    setVisible(!visible);

  }

  const _getLanguageCode = () => {

    //console.log('debug');
    //console.log(language);


    if (language.locale === 'my') {
      //return 'ms';
    }

    if (language.locale === 'en') {
      return 'gb';
    }

    if (language.locale === 'ja') {
      return 'jp';
    }

    return language.locale;

  }


  if (!user) {
    return false;
  }


  const flags = (c) => {

    if (c === 'en') {
      return 'gb';
    }
    if (c === 'ja') {
      return 'jp';
    }
    if (c === 'zh') {
      return 'cn';
    }
    if (c === 'ar') {
      return 'ae';
    }

    if (c === 'ms') {
      return 'my';
    }

    if (c === 'uk') {
      return 'ua';
    }

    return c;

  }

  const tt = {
    "de": "german",
    "fr": "french",
    "en": "english",
    "es": "spanish",
    "it": "italian",
    "nl": "dutch",
    "ru": "russian",
    "hu": "hungarian",
    "ja": "japanese",
    "my": "malaysian",
  }
  const languages = [];

  if (typeof userLanguage.published === 'object') {
    userLanguage.published.map((o) => {
      languages.push(
        {
          code: o.iso2,
          iso2: o.iso2,
          iso3: o.iso3
        }
      );
    })
  }

  if (hasRole(user.roles, 'translator') && typeof userLanguage.progress === 'object') {
    userLanguage.progress.map((o) => {
      languages.push(
        {
          code: o.iso2,
          iso2: o.iso2,
          iso3: o.iso3
        }
      );
    })
  }

  const content = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      <a className="isoDropdownLink" onClick={() => {



      }} href="# ">
        babel
      </a>

      {
        languages.map((ll) => {
          return (
            <a className="isoDropdownLink" onClick={() => {

              if (ll.iso2 === 'ms') {
                ll.code = 'my';
                ll.iso2 = 'my';
                ll.iso3 = 'mys';
              }

              let n = 'english';
              if (tt[ll.iso2]) {
                n = tt[ll.iso2];
              }



              console.log(12345);
              console.log(ll);
              console.log(n);


              i18n.changeLanguage(ll.iso2);
              //i18n.reloadResources();
              dispatch(actions.changeLanguage(n));
              handleVisibleChange();
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }} href="# ">
              <ReactCountryFlag countryCode={flags(ll.code)} svg /> &nbsp;&nbsp;
              {t(`universal:messages.uni_lang_${ll.iso3}`)}
            </a>
          )
        })
      }



    </TopbarDropdownWrapper>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Popover
        content={content}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">

          <ReactCountryFlag countryCode={_getLanguageCode()} svg />
        </div>
      </Popover>
    </div>
  );
}


export default App;




