import { SET_COUNTRY } from "../business/constants";

const initState = [];

export default function countryReducer(state = initState, action) {
  switch (action.type) {
    case SET_COUNTRY:

      return action.payload;

    default:
      return state;
  }
}
