import React, { Component } from "react";


class AlertStorage extends React.Component {
  constructor(props) {
    super(props)

    this.localStorageUpdated = this.localStorageUpdated.bind(this)
    this.state = {
      status: null,
      business_uuid: localStorage.getItem('business_uuid')
    }
  }
  componentDidMount() {
    if (typeof window !== 'undefined') {
      //this.setState({ status: localStorage.getItem('business_uuid') ? true : false })

      window.addEventListener('storage', this.localStorageUpdated)
    }
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('storage', this.localStorageUpdated)
    }
  }

  localStorageUpdated() {

    if (this.state.business_uuid !== localStorage.getItem('business_uuid') && typeof this.state.business_uuid === 'string' && typeof localStorage.getItem('business_uuid') === 'string') {
      document.location.reload()
    }


  }

  render() {
    return false;

  }
}

export default AlertStorage;
