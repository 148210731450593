import config, {
  getDefaultLanguage
} from 'containers/LanguageSwitcher/config';


const initState = getDefaultLanguage().locale;

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case "CHANGE_LANGUAGE":
      return action.language.locale;

    default:
      return state;
  }
  return state;
}
