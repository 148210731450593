import { SET_RESTAURANT_FILTER_PRODUCT_ATTRIBUTES } from "../business/constants";

const initState = [];

export default function businessFilterproductattributeReducer(state = initState, action) {
  switch (action.type) {
    case SET_RESTAURANT_FILTER_PRODUCT_ATTRIBUTES:

      return action.payload;

    default:
      return state;
  }
}
