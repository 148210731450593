import { SET_REGIMES } from "../business/constants";

const initState = [];

export default function regimeReducer(state = initState, action) {
  switch (action.type) {
    case SET_REGIMES:

      return action.payload;

    default:
      return state;
  }
}
