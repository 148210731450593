export const INIT = "INIT";
export const SET_REGIMES = "SET_REGIMES";
export const SET_PAYMENTS = "SET_PAYMENTS";
export const SET_SOCIALS = "SET_SOCIALS";
export const SET_SERVICES = "SET_SERVICES";
export const SET_SETTING_PRODUCT = "SET_SETTING_PRODUCT";
export const SET_ACTIVITIES = "SET_ACTIVITIES";
export const SET_SPECIALITY_FOODS = "SET_SPECIALITY_FOODS";
export const SET_CASH_REGISTER = "SET_CASH_REGISTER";
export const SET_PRICE_VALUES = "SET_PRICE_VALUES";
export const SET_INGREDIENT_CATEGORIES = "SET_INGREDIENT_CATEGORIES";
export const SET_DELIVERY_PARTNER = "SET_DELIVERY_PARTNER";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_TAX = "SET_TAX";
export const SET_ORDER_STATUS = "SET_ORDER_STATUS";

export const UPLOAD_IMG_LAYOUT = "UPLOAD_IMG_LAYOUT";
export const UPLOAD_IMG_CATALOG = "UPLOAD_IMG_CATALOG";

export const UPDATE_RESTAURANT_INFO = "UPDATE_RESTAURANT_INFO";
export const UPDATE_RESTAURANT_ADDRESS = "UPDATE_RESTAURANT_ADDRESS";

export const LOAD_RESTAURANT_INFO = "LOAD_RESTAURANT_INFO";

export const ADD_RESTAURANT_PRODUCT = "ADD_RESTAURANT_PRODUCT";
export const DELETE_RESTAURANT_PRODUCT = "DELETE_RESTAURANT_PRODUCT";
export const UPDATE_RESTAURANT_PRODUCT = "UPDATE_RESTAURANT_PRODUCT";
export const ADD_RESTAURANT_SHOWTIME = "ADD_RESTAURANT_SHOWTIME";
export const DELETE_RESTAURANT_SHOWTIME = "DELETE_RESTAURANT_SHOWTIME";
export const UPDATE_RESTAURANT_SHOWTIME = "UPDATE_RESTAURANT_SHOWTIME";
export const DELETE_RESTAURANT_OPTION = "DELETE_RESTAURANT_OPTION";
export const ADD_RESTAURANT_OPTION = "ADD_RESTAURANT_OPTION";
export const UPDATE_RESTAURANT_OPTION = "UPDATE_RESTAURANT_OPTION";
export const DELETE_RESTAURANT_OPTION_VALUE = "DELETE_RESTAURANT_OPTION_VALUE";
export const ADD_RESTAURANT_OPTION_VALUE = "ADD_RESTAURANT_OPTION_VALUE";
export const UPDATE_RESTAURANT_OPTION_VALUE = "UPDATE_RESTAURANT_OPTION_VALUE";

export const UPDATE_RESTAURANT_MENU = "UPDATE_RESTAURANT_MENU";
export const SET_USER_LANGUAGE = "SET_USER_LANGUAGE";

export const SET_RESTAURANT_PAYMENTS = "SET_RESTAURANT_PAYMENTS";
export const SET_RESTAURANT_REGIMES = "SET_RESTAURANT_REGIMES";
export const SET_RESTAURANT_OPTIONS = "SET_RESTAURANT_OPTIONS";
export const SET_RESTAURANT_LANGUAGES = "SET_RESTAURANT_LANGUAGES";
export const SET_RESTAURANT_ATTRIBUTES = "SET_RESTAURANT_ATTRIBUTES";
export const SET_RESTAURANT_PRICINGS = "SET_RESTAURANT_PRICINGS";
export const SET_RESTAURANT_INFO = "SET_RESTAURANT_INFO";
export const SET_RESTAURANT_PROMOCODES = "SET_RESTAURANT_PROMOCODES";
export const SET_RESTAURANT_MENUS = "SET_RESTAURANT_MENUS";
export const SET_STATIONS = "SET_STATIONS";
export const SET_ZONES = "SET_ZONES";
export const SET_RESTAURANT_PRODUCTS = "SET_RESTAURANT_PRODUCTS";
export const SET_RESTAURANT_SHOWTIMES = "SET_RESTAURANT_SHOWTIMES";
export const SET_RESTAURANT_FILTER_PRODUCT_ATTRIBUTES =
  "SET_RESTAURANT_FILTER_PRODUCT_ATTRIBUTES";
