import update from "immutability-helper";
import {
  SET_RESTAURANT_SHOWTIMES,
  ADD_RESTAURANT_SHOWTIME,
  DELETE_RESTAURANT_SHOWTIME,
  UPDATE_RESTAURANT_SHOWTIME
} from "../business/constants";

const initState = [];

export default function businessShowtimeReducer(state = initState, action) {
  switch (action.type) {
    case SET_RESTAURANT_SHOWTIMES:
      return action.payload;
    case ADD_RESTAURANT_SHOWTIME: {
      return update(state, {
        $push: [action.payload]
      });
    }
    case DELETE_RESTAURANT_SHOWTIME: {

      const index = state.findIndex(
        item => item.uuid === action.payload
      );
      if (index !== -1) {
        return update(state, {
          $splice: [[index, 1]]
        });
      }

      return state;
    }
    case UPDATE_RESTAURANT_SHOWTIME: {

      const index = state.findIndex(
        item => item.uuid === action.payload.uuid
      );
      if (index !== -1) {
        return update(state, {
          [index]: { $set: action.payload }
        });
      }

      return state;
    }

    default:
      return state;
  }
}
