import { SET_RESTAURANT_REGIMES } from "../business/constants";

const initState = [];

export default function businessRegimerReducer(state = initState, action) {
  switch (action.type) {
    case SET_RESTAURANT_REGIMES: {

      if (typeof action.payload === 'object') {
        return action.payload;
      }
      return state;
    }

    default:
      return state;
  }
}
