import Auth from "./auth/reducer";
import App from "./app/reducer";
import User from "./user/reducer";

import OrderStatus from "./orderStatus/reducer";
import Country from "./country/reducer";
import Tax from "./tax/reducer";
import Station from "./station/reducer";
import Zone from "./zone/reducer";
import Regime from "./regime/reducer";
import Payment from "./payment/reducer";
import Social from "./social/reducer";
import Service from "./service/reducer";
import SettingProduct from "./settingProduct/reducer";
import Activity from "./activity/reducer";
import SpecialityFood from "./specialityFood/reducer";
import PriceValue from "./priceValue/reducer";
import IngredientCategory from "./ingredientCategory/reducer";

import CashRegister from "./cashregister/reducer";
import DeliveryPartner from "./deliveryPartner/reducer";
import LanguageSwitcher from "./languageSwitcher/reducer";
import ThemeSwitcher from "./themeSwitcher/reducer";

import Firestore from "./firestore/reducer";

import BusinessAttribute from "./businessAttribute/reducer";
import BusinessInfo from "./businessInfo/reducer";
import BusinessFilterProductAttribute from "./businessFilterproductattribute/reducer";
import BusinessLanguage from "./businessLanguage/reducer";
import BusinessMenu from "./businessMenu/reducer";
import BusinessPricing from "./businessPricing/reducer";
import BusinessProduct from "./businessProduct/reducer";
import BusinessPromocode from "./businessPromocode/reducer";
import BusinessRegime from "./businessRegime/reducer";
import BusinessPayment from "./businessPayment/reducer";

import BusinessOption from "./businessOption/reducer";
import BusinessShowtime from "./businessShowtime/reducer";

import lang from "./lang/reducer";
import userLanguage from "./userLanguage/reducer";

export default {
  Auth,
  App,
  User,
  CashRegister,
  DeliveryPartner,
  Payment,
  Station,
  Zone,
  Country,
  OrderStatus,
  PriceValue,
  IngredientCategory,
  Tax,
  Regime,
  Social,
  Service,
  SettingProduct,
  Activity,
  SpecialityFood,
  LanguageSwitcher,
  ThemeSwitcher,
  Firestore,
  BusinessAttribute,
  BusinessFilterProductAttribute,
  BusinessInfo,
  BusinessOption,
  BusinessPricing,
  BusinessRegime,
  BusinessPayment,
  BusinessLanguage,
  BusinessPromocode,
  BusinessProduct,
  BusinessShowtime,
  BusinessMenu,
  lang,
  userLanguage,
};
