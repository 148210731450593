import update from "immutability-helper";

import { SET_RESTAURANT_INFO, UPDATE_RESTAURANT_INFO, UPDATE_RESTAURANT_ADDRESS } from "../business/constants";

const initState = {};

export default function businessInfoReducer(state = initState, action) {
  switch (action.type) {
    case SET_RESTAURANT_INFO:
      return action.payload;
    case UPDATE_RESTAURANT_INFO: {
      return action.payload;
    }
    case UPDATE_RESTAURANT_ADDRESS: {

      const index = state.addresses.findIndex(
        item => item.uuid === action.payload.uuid
      );


      if (index !== -1) {

        return update(state, {
          addresses: { [index]: { $set: action.payload } }
        });
      } else {
        return update(state, {
          addresses: { $push: [action.payload]  }
        }); 
      }

      return state;
    }



    default:
      return state;
  }
}
