export const isHandleSettingProduct = (business, settingProduct, key) => {

    const match = settingProduct.find((sp) => sp.k === key);
    if (match && business?.whitelabel?.settingProduct.includes(match.id.toString())) {
      return true;
    }
    return false;

  
};
  
export default isHandleSettingProduct
  
  
  