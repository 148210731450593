import { SET_PRICE_VALUES } from "../business/constants";

const initState = [];

export default function priceValueReducer(state = initState, action) {
    switch (action.type) {
        case SET_PRICE_VALUES:
            return action.payload;

        default:
            return state;
    }
}
