import { SET_INGREDIENT_CATEGORIES } from "../business/constants";

const initState = [];

export default function ingredientCategoryReducer(state = initState, action) {
    switch (action.type) {
        case SET_INGREDIENT_CATEGORIES:
            return action.payload;

        default:
            return state;
    }
}
