export function getView(width) {
  let newView = "MobileView";
  if (width > 1220) {
    newView = "DesktopView";
  } else if (width > 767) {
    newView = "TabView";
  }
  return newView;
}
const actions = {
  FETCHING: "FETCHING",
  COLLPSE_CHANGE: "COLLPSE_CHANGE",
  COLLPSE_OPEN_DRAWER: "COLLPSE_OPEN_DRAWER",
  CHANGE_OPEN_KEYS: "CHANGE_OPEN_KEYS",
  TOGGLE_ALL: "TOGGLE_ALL",
  CHANGE_CURRENT: "CHANGE_CURRENT",
  CLEAR_MENU: "CLEAR_MENU",

  SEARCH_PRODUCT: "SEARCH_PRODUCT",
  SEARCH_CATEGORY: "SEARCH_CATEGORY",
  SEARCH_FORM: "SEARCH_FORM",
  SEARCH_INGREDIENT: "SEARCH_INGREDIENT",
  SEARCH_OPTION: "SEARCH_OPTION",
  SEARCH_TABLE: "SEARCH_TABLE",

  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE
  }),
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== "DesktopView";
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height
    };
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER
  }),
  changeOpenKeys: openKeys => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys
  }),
  changeCurrent: current => ({
    type: actions.CHANGE_CURRENT,
    current
  }),
  clearMenu: () => ({ type: actions.CLEAR_MENU }),
  setFetching: (payload) => ({
    type: actions.FETCHING,
    payload
  }),

  setSearchProduct: (payload) => ({
    type: actions.SEARCH_PRODUCT,
    payload
  }),
  setSearchCategory: (payload) => ({
    type: actions.SEARCH_CATEGORY,
    payload
  }),
  setSearchForm: (payload) => ({
    type: actions.SEARCH_FORM,
    payload
  }),
  setSearchIngredient: (payload) => ({
    type: actions.SEARCH_INGREDIENT,
    payload
  }),
  setSearchOption: (payload) => ({
    type: actions.SEARCH_OPTION,
    payload
  }),
  setSearchTable: (payload) => ({
    type: actions.SEARCH_TABLE,
    payload
  }),


};
export default actions;
